.choose-role {

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .card {
    margin-bottom: 0px;
  }

  .card-text {
    text-align: center;
  }

  .btn-card {
    &:hover {
      box-shadow: 0 8px 25px -8px #22292f;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 30px 30px;
  }
}

@media (max-width: 576px) {
  .choose-role {
  
    .modal-title {
      font-size: 20px;
    }

    .modal-body {
      padding: 20px 1.4rem;
    }

    .card-title {
      font-size: 14px;
    }
  
    .btn-card {
      &:hover {
        box-shadow: 0 8px 25px -8px #22292f;
        cursor: pointer;
      }
    }
  }
}