.greeting-place {
  flex-direction: row;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    align-self: center;
  }

  .card-body {
    flex: 3;
    text-align: center;
  }

  .card-img {
    flex: 1;
    padding: 10px;
    display: flex;
  }

  .smile-icon {
    cursor: pointer;
    
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
}

@media (min-width: 768px) {
  .greeting-place {
    
    .card-img {
      height: 268px;
    }
    
    .card-subtitle {
      padding-top: 5px;
      padding-bottom: 20px;
    }
  }
}

@media (min-width: 1200px) {
  .greeting-place {
    .card-img {
      height: 178px;
    }
  }
}
