
.current-lesson {

  .meetup-img-wrapper {
    display: flex;
    flex-direction: row;
    background-color: rgba(234, 84, 85, 0.1);

    .title {
      text-align: center;
      align-self: center;
    }

    .ilustration {

      img {
        max-width: 100%;
        max-height: 100%;
        width: 200px;
        height: auto;
      }
    }
  }

  .buy {

    button {
      width: 100%;
    }
  }
}