.card-developer-meetup {
  .meetup-img-wrapper {
    .title {
      font-size: 1.285rem;
    }
  }
}

.nearest-lesson {
  .meetup-img-wrapper {
    display: flex;
    flex-direction: row;

    .title {
      text-align: center;
      align-self: center;
    }

    .ilustration {
      img {
        max-width: 100%;
        max-height: 100%;
        width: 200px;
        height: auto;
      }
    }
  }

  .btn-place {
    display: block;

    .reschedule {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 3;
    }

    .buy {
      flex: 1;
    }

    button {
      width: 100%;
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
}

.next-lesson-link {
  padding: 10px 12px;
  text-align: center;
  margin-top: -10px;

  a:hover {
    opacity: 0.6;
  }
}
