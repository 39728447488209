//for footer
.auth-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: $gray-50;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}