.ishkola-spinner {
  .block {
    position: relative;
    min-height: 100px;
  }

  img {
    display: block;
    top: 0px;
  }

  .loading {
    top: 19%;
  }
}

.component__switch {
  $transition: .3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &_item {
    display: flex;
    flex-direction: column;
    width: 40px;
    border: 1px solid var(--green);
    border-radius: 15px;
    padding: 3px;
    background-color: var(--white);
    position: relative;
    transition: $transition;
  }

  &_handler {
    width: 15px;
    height: 15px;
    background-color: var(--gray);
    border-radius: 50%;
    transition: $transition;
  }

  &.active &_item {
    background-color: var(--green);
  }

  &.active &_handler {
    background-color: var(--white);
    transform: translateX(18px);
  }

  &_label {
    color: var(--gray);
    font-weight: bold;
    margin-left: 10px;
  }

  &.active &_label {
    color: var(--green);
  }

  &.disabled {
    opacity: .45;
    cursor: initial;
  }
}

$colors: (
  'primary': #7367F0,
  'secondary': #808390,
  'success': #28C76F,
  'error': #FF4C51,
  'info': #00BAD1,
  'warning': #FF9F43
);

@each $color_name, $color in $colors {
  .button__color__#{$color_name} {
    color: $color!important;
  }
}

.component {

  &__button {
    cursor: pointer;

    &__disabled {
      opacity: 0.6!important;
      color: #999CA6!important;
      cursor: default!important;
    }

    &:hover {
      opacity: 0.8;
    }

    &__icon {

    }
  }
}