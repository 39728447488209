.past-lesson {
  .meetup-img-wrapper {
    display: flex;
    flex-direction: row;
    background-color: rgba(195, 239, 215, 0.1);

    .title {
      text-align: center;
      align-self: center;
    }

    .ilustration {

      img {
        max-width: 100%;
        max-height: 100%;
        width: 200px;
        height: auto;
      }
    }
  }

  .files {
    flex: 4;
    overflow: hidden;
    cursor: pointer;

    .text-icon {
      position: absolute;
      top: 8px;
      left: 5px;
    }

    .list-group {
      overflow: hidden;

      .list-group-item {
        padding-left: 5px;
        position: relative;
    
        &:active {
          opacity: 0.6;
        }

        small {
          margin-left: 30px;
          white-space: nowrap;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0px;
          top: 0px;
          bottom: 0px;
          width: 50px;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 74%);
        }
      }
    }
  }

  .stream {
    flex: 2;
    align-items: center;
    justify-content: center;
    display: flex;

    .icon-stream {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }

  .media {
    margin-bottom: 2rem;
  }
}

//темная тема
.dark-layout {
  .past-lesson {
    .list-group {
      .list-group-item {
        &::after {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(40, 48, 70, 1) 74%);
        }
      }
    }
  }
}

.next-lesson-link {
  padding: 10px 12px;
  text-align: center;
  margin-top: -10px;

  a:hover {
    opacity: 0.6;
  }
}
